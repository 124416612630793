
<template>
  <header-layout  @french="french()" @english="english()" :language="language"></header-layout>
  <div class="page-content">
    <RouterView :language="language"> </RouterView>
  </div>
</template>

<script>
import language_en from "../src/language_en.json"
import language_fr from "../src/language_fr.json"
import HeaderLayout from "./components/layout/Header_layout.vue"

export default {
  name: 'App',
  components: {
    HeaderLayout,
  },
  data(){
   return{
    language:"",
  }
   },
   methods:{
           english(){
            this.language=language_en
        },
        french(){
          this.language=language_fr
        }
        },
    mounted(){
          this.english()
          },
  }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
