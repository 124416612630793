<template>
     <div class="page-section">
       <div class="container">
         <div class="text-center">
           <h2 class="title-section mb-3">Stay in touch</h2>
           <p>Just say hello or drop us a line. You can manualy send me email on <a href="mailto:nche.jireh@gmail.com">nche.jireh@outlook.com</a></p>
           <p>Email: nche.jireh@outlook.com </p>
             <p> Phone: (+237) 676 829,907 </p>
             <p><b>Postal address: ...</b></p>
         </div>
         <div class="row justify-content-center mt-5 contact">
           <div class="col-lg-8 card">
             <form action="#" class="form-contact">
               <div class="row g-3 align-items-center">
                 <div class="col-auto">
                   <label  for="inputPassword6" class="col-form-label">{{language.contact[0]}}</label>
                   <div class="col-auto">
                   <input type="text" class="form-control" id="name" placeholder="Enter name.." v-model="message.name">
                  </div>
                 </div>
                 <div class="col-sm-6 py-2">
                   <label for="email" class="fg-grey">{{language.contact[1]}}</label><input type="text" class="form-control" id="email" placeholder="Email address.." v-model="message.email">
                 </div>
                 <div class="col-12 py-2">
                   <label for="subject" class="fg-grey">{{language.contact[2]}}</label>
                   <input type="text" class="form-control" id="subject" placeholder="Subject.." v-model="message.subject">
                 </div>
                 <div class="col-12 py-2">
                   <label for="message" class="fg-grey">{{language.contact[3]}}</label>
                   <textarea id="message" rows="8" class="form-control" placeholder="Enter message.." v-model="message.message"></textarea>
                 </div>
                 <div class="col-12 mt-3">
                   <button type="submit" class="btn btn-primary px-5 my-3"></button>
                 </div>
               </div>
             </form>
           </div>
         </div>
       </div> <!-- .container -->
     </div> <!-- .page-section -->
 <h>Data {{ message }}</h>
 </template>
 
 <script>
 export default {
props: {
language:Object
},
    data(){
     
            return {
              message:{},
              
              }
          },
  mounted(){
  },
    }
 </script>



