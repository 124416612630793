import {createWebHistory, createRouter} from "vue-router"
import Home from "./components/home-com.vue"
import Contact from "./components/contact/contact-com.vue"
import NotFound from "./components/notFound.vue"



const routes=[
    {
        path:"/",
        name: "Home",
        component: Home,
    },
    {
        path:"/contact",
        name: "Contact",
        component: Contact,
    },
    {
        path:"/:pathMatch(.*)*",
        name: "NotFoundPage",
        component: NotFound,
    }
]

const router=createRouter({
    history:createWebHistory(),
    routes: routes,
    linkActiveClass: 'active',
})

export default router