<template>
    <!-- Back to top button -->
       <header>
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
           <div class="container-fluid"> 
            <a data-fancybox="portfolio">
              <img src="" alt="" class="img-fluid">
            </a>
            <button class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
           </div>
            <div class="navbar-collapse collapse" id="navbarContent">
              <ul class="navbar-nav me-auto pt-3 pt-lg-0">
                <li class="nav-item " >
                  <router-link to="/" :language="language"  class="nav-link">Home</router-link>
                  <!-- <a href="" class="nav-link" @click="pageComp('home-comp')"  style="cursor:pointer">Home</a> -->
                </li>
                <!-- <li class="nav-item">
                   <router-link to="/about" class="nav-link">About</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/activity" class="nav-link">Activities</router-link>
                </li> -->
                <li class="nav-item">
                   <router-link to="/contact" class="nav-link">Contact</router-link>
                </li>
                
    <div class="dropdown">
      <button class="btn dropdown-toggle btn-sm" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        EN
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item" @click="english">english</a>
        <a class="dropdown-item" @click="french">française</a>
      </div>
    </div>
              </ul>
            </div>
         </nav> <!-- .navbar -->
        </header>
    </template>
    
    
    <script>
    export default{
     props:{
      language:Object
     },
      data(){
        return{
         }
    
      },
      methods: {
        french(){
        this.$emit('french');
        },
        english(){
        this.$emit('english');
        }
        
        }
    }
    </script>
    
<style scoped>
      </style>