<template>
  <div class="card text-start mb-3" >
  <div class="row g-0" style="background-color: #d9d6ce">
    <div class="col-md-4">
      <div class="card-body">
        <h4 class="card-title ">NCHE JIREH CHE</h4>
        <p class="card-text">(+237) 676 829 907</p>
        <p class="card-text">nche.jireh@outlook.com</p>
        <a class="card-text"><small class="text-body-secondary">www.linkedin.com/in/nche-jireh-59936b123</small></a>
      </div>
    </div>
    <div class="col-md-8"  >
      <img src="../assets/logo.png" class="rounded float-end card-img-right" alt="" style="max-width: 300px;">
    </div>

  </div>
</div>

<div class="card text-center">
  <div class="card-body">
    <h2 class="card-title">PROCESS ENGINEER</h2>
    <p class="card-text">Bilingual (English & French )</p>
    <p class="card-text">Mastery of software (CHEMCAD, SOLIDWORKS®, R®, Microsoft Office®; Python, MySQL )</p>
  </div>

</div>

<div class="card border-success mb-3" >
  <div class="card-header border-success" style="background-color: #d9d6ce">Summary</div>
  <div class="card-body text-success">
    <p class="card-title">Trained process engineer with an extensive background in engineering principles, designing, and
implementation of systems, data collection, data analysis, and the management of safety and environmental
impact assessment studies. Strategic thinker with the ability to improve efficiencies by assessing and enhancing
company processes. I am equally successful in conducting presentations and demos for technical and
nontechnical audiences.</p>
  </div>
</div>

</template>

<script scoped>
</script>